<template>
  <div>
    <!-- Button to toggle visibility -->
    <button v-if="!isVisible" class="toggle-button" @click="toggleWidget">💡 Get Help</button>

    <!-- Floating widget with iframe -->
    <div v-if="isVisible" class="floating-widget">
      <!-- Close button inside the iframe -->
      <button class="close-button" @click="toggleWidget">❌</button>
      <div class="iframe-container">
        <iframe src="https://jaknaker.org/" frameborder="0" allowfullscreen class="iframe-content"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatingWidget',
  data() {
    return {
      isVisible: false, // Controls the visibility of the widget
    }
  },
  methods: {
    toggleWidget() {
      this.isVisible = !this.isVisible // Toggle visibility
    },
  },
}
</script>

<style scoped>
/* Floating widget styling */
.floating-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  min-width: 380px;
  height: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  background-color: white;
}

/* Responsive iframe styling */
iframe {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  border-radius: 8px;
}

/* Container for iframe to ensure responsive sizing */
.iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Toggle button styling (appears when widget is hidden) */
.toggle-button {
  position: fixed;
  bottom: 25px;
  right: 20px;
  padding: 12px 20px;
  background-color: #154ec1;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  z-index: 10000; /* Ensure button is on top */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.3s ease,
    background-color 0.3s ease,
    box-shadow 0.3s ease;
}

/* Hover effect for the toggle button */
.toggle-button:hover {
  background-color: #2c5ec2;
  transform: translateY(-4px); /* Slight lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Close button inside the iframe */
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  /* background-color: #ff4757; */
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  z-index: 10000; /* Ensure button is on top of the iframe */
  transition: background-color 0.3s ease;
}

/* Animation for the toggle button visibility when widget is shown */
.toggle-button {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.floating-widget {
  animation: bounceIn 0.5s;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  80% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.toggle-button[v-cloak] {
  opacity: 0;
}

@media screen and (max-width: 480px) {
  .floating-widget {
    width: 100%;
    height: 100%;
    border-radius: 0;
    bottom: 0;
    right: 0;
  }
}
</style>
